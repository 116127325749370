<template>
  <div style="border: 1px solid #ccc">
    <!-- 工具栏 -->
    <Toolbar style="border-bottom: 1px solid #ccc" :editor="editor" :defaultConfig="toolbarConfig" />
    <!-- 编辑器 -->
    <Editor style="height: 500px; overflow-y: hidden" :defaultConfig="editorConfig" :defaultHtml="modelVal" @onChange="onChange" @onCreated="handleCreated" />
  </div>
</template>

<script>
import { Editor, Toolbar } from "@wangeditor/editor-for-vue";
// import { DomEditor } from "@wangeditor/editor";
import systemManager from "@/request/systemManager.js";
export default {
  name: "MyEditor",
  components: { Editor, Toolbar },
  data () {
    return {
      editor: null,
      editorId: "wangEditor-lz",
      toolbarConfig: {
        excludeKeys: [
          "group-video",
          "codeBlock",
          "insertLink",
          "todo",
          "divider",
        ],
      },
      mode: 'default', // or 'simple'
      editorConfig: {
        placeholder: "请输入内容...",
        MENU_CONF: {
          uploadImage: {
            async customUpload (res, insertFn) {
              let { data } = await systemManager.uploadfile(res)
              insertFn(data[0].sourceImg);
            },
          },
        },
      },
    };
  },
  model: {
    prop: "modelVal", //指向props的参数名
    event: "change", //事件名称
  },
  props: {
    modelVal: [String],
  },
  methods: {
    onChange (editor) {
      // console.log('111111', this.getEditHtmlDta(editor.getHtml()))
      this.$emit("change", editor.getHtml() === '<p><br></p>' ? '' : this.getEditHtmlDta(editor.getHtml()));
    },
    handleCreated (editor) {
      this.editor = Object.seal(editor) // 一定要用 Object.seal() ，否则会报错
    },
    // 处理编辑器内容样式
    getEditHtmlDta (htmi_value) {
      let newContent = htmi_value
      // table 样式
      newContent = newContent.replace(/\<table/gi, '<table style="width:100%;border-top: 1px solid #ccc;border-left: 1px solid #ccc;"');
      newContent = newContent.replace(/\<th/gi, '<th style="border-bottom: 1px solid #ccc;border-right: 1px solid #ccc;text-align: center;padding: 3px 5px;background-color:#f5f2f0;"');
      newContent = newContent.replace(/\<td/gi, '<td style="border-bottom: 1px solid #ccc;border-right: 1px solid #ccc;padding: 3px 5px;"');
      // blockquote  样式
      newContent = newContent.replace(/\<blockquote/gi, '<blockquote style="display: block;border-left: 8px solid #d0e5f2;padding: 5px 10px;margin: 10px 0;line-height: 1.4;font-size: 100%;background-color: #f1f1f1;"');
      // ul ol 样式
      newContent = newContent.replace(/\<ul/gi, '<ul style="margin: 10px 0 10px 20px;"');
      newContent = newContent.replace(/\<ol/gi, '<ol style="margin: 10px 0 10px 20px;"');
      newContent = newContent.replace(/\<li/gi, '<li style="line-height: inherit;"');


      return newContent
    }
  },
  beforeDestroy () {
    const editor = this.editor
    if (editor == null) return
    editor.destroy() // 组件销毁时，及时销毁编辑器
  }
};
</script>
<style src="@wangeditor/editor/dist/css/style.css"></style>

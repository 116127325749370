var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticStyle: { border: "1px solid #ccc" } },
    [
      _c("Toolbar", {
        staticStyle: { "border-bottom": "1px solid #ccc" },
        attrs: { editor: _vm.editor, defaultConfig: _vm.toolbarConfig },
      }),
      _c("Editor", {
        staticStyle: { height: "500px", "overflow-y": "hidden" },
        attrs: { defaultConfig: _vm.editorConfig, defaultHtml: _vm.modelVal },
        on: { onChange: _vm.onChange, onCreated: _vm.handleCreated },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }